<template>
  <div>
    <b-row>
      <b-col
        v-if="!hideOfferProcessInCart"
        sm="12"
        md="6"
      >
        <label
          v-b-tooltip
          for="offerRadio"
          class="w-100"
          :title="$t('GenerateOfferBox')"
        >
          <b-card
            no-body
            class="large-step-card border cursor-pointer px-1 pt-1 pb-50"
            :class="isOfferPath ? 'border-primary' : ''"
          >
            <div class="d-flex justify-content-between">
              <h4 class="d-flex align-items-center">
                <b-form-radio
                  id="offerRadio"
                  v-model="$store.state.cart.type"
                  variant="primary"
                  inline
                  value="OFFER"
                  @change="changeSettings($event, 'type')"
                />
                {{ $t('GenerateOffer1') }}
              </h4>
              <feather-icon
                icon="AlertCircleIcon"
                class="text-primary"
                size="18"
              />
            </div>

          </b-card>
        </label>
      </b-col>
      <b-col
        v-if="!hideOrderProcessInCart && checkRequiredModule('orderModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_ORDER_ADD])"
        sm="12"
        md="6"
      >
        <label
          v-b-tooltip
          for="orderRadio"
          class="w-100"
          :title="$t('GenerateOrderBox')"
        >
          <b-card
            no-body
            class="large-step-card border cursor-pointer px-1 pt-1 pb-50"
            :class="!isOfferPath ? 'border-primary' : ''"
          >
            <div class="d-flex justify-content-between">
              <h4 class="d-flex align-items-center">
                <b-form-radio
                  id="orderRadio"
                  v-model="$store.state.cart.type"
                  inline
                  value="ORDER"
                  variant="primary"
                  @change="changeSettings($event, 'type')"
                />
                {{ $t('GenerateOrder') }}
              </h4>
              <feather-icon
                icon="AlertCircleIcon"
                class="text-primary"
                size="18"
              />
            </div>
          </b-card>
        </label>
      </b-col>
    </b-row>

    <b-row class="d-flex flex-row-reverse position-relative">
      <slot name="overlay" />
      <!--    Views: Order    -->
      <b-col
        sm="12"
        md="12"
        lg="4"
        class="pl-lg-25 pl-md-1 mb-1 mb-lg-0"
      >

        <checkout-summary />
        <!--    Order Content    -->
        <!-- Agreement Module -->
        <div v-if="(checkRequiredModule('agreementModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF], [$roles.CONTACT_THREAD_AGREEMENT_ADD])) || (checkRequiredModule('paymentModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_PAYMENT_ADD]))">
          <b-button
            :key="cart.deliveryAddressType"
            variant="primary"
            class="w-100 mb-0"
            :disabled="isButtonDisabled()"
            @click="nextStep"
          >
            {{ checkRequiredModule('agreementModule') || checkRequiredModule('paymentModule') ? $t('Next') : ((cart.type === 'OFFER' ? $t('Offer.CreateAnOffer') : $t('Offer.FinishYourOrder'))) }}
          </b-button>
        </div>
        <!--    Order Options    -->
        <div
          v-else
          class="d-flex"
        >
          <!--    Order End Button    -->
          <b-button
            variant="primary"
            class="mb-50 flex-grow-1 mr-50"
            :disabled="isButtonDisabled()"
            @click="createOffer"
          >
            {{ checkRequiredModule('agreementModule') || checkRequiredModule('paymentModule') ? $t('Next') : ((cart.type === 'OFFER' ? $t('Offer.CreateAnOffer') : $t('Offer.FinishYourOrder'))) }}          </b-button>
          <!--    Order Cancel Button    -->
          <b-button
            variant="danger"
            class="mb-50"
            :to="{ name: 'shop' }"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-col>

      <!--    Views: Products    -->
      <b-col
        sm="12"
        md="12"
        lg="8"
      >
        <!--    Products Content    -->
        <div class="shopCart d-flex flex-column">
          <!--    Cart Items    -->
          <div ref="container">
            <b-card v-if="isOfferPath">
              <section-generating-offer @change-settings="changeSettings" />
            </b-card>
            <section-generationg-order
              v-else
              ref="order"
              @change-settings="changeSettings"
            />

            <b-overlay
              :opacity=".8"
              :show="loading"
              no-wrap
              spinner-variant="primary"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  EDIT_CONTACT, GET_USER_CART, UPDATE_CART, UPLOAD_FILES,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import SectionGeneratingOffer from '@/views/offers/checkout/SectionGeneratingOffer.vue'
import SectionGenerationgOrder from '@/views/offers/checkout/SectionGeneratingOrder.vue'
import { grossPrice, netPriceAfterCartDiscount, netPriceByAmount } from '@/helpers/cartProductPrices'
import { VBTooltip } from 'bootstrap-vue'
import CheckoutSummary from '@/views/offers/checkout/CheckoutSummary.vue'
import axiosIns from '@/libs/axios'

export default {
  components: { SectionGenerationgOrder, SectionGeneratingOffer, CheckoutSummary },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    totalGross: {
      type: [String, Number],
      default: 0,
    },
    hideOrderProcessInCart: {
      type: Boolean,
      default: false,
    },
    hideOfferProcessInCart: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    loading: false,
    threads: [],
    thread: '',
  }),
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      system: 'system/getSettings',
      products: 'cart/getCartProducts',
    }),
    isOfferPath() {
      return this.$store.state.cart.type === 'OFFER'
    },

    totalProductsGrossPrice() {
      this.$emit('set-total-price', (this.products.length ? this.products.reduce((a, b) => +a + (this.grossPrice(b)), 0) : 0))
      return this.products.length ? this.products.reduce((a, b) => +a + (this.grossPrice(b)), 0) : 0
    },
    totalProductsNetPrice() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.netPriceByAmount(b)), 0) : 0
    },
    totalProductsNetPriceAfterDiscount() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.netPriceAfterCartDiscount(b)), 0) : 0
    },
    isOtherAddressFilled() {
      return (!this.cart.otherAddress.city || !this.cart.otherAddress.country || !this.cart.otherAddress.number || !this.cart.otherAddress.postalCode || !this.cart.otherAddress.street)
    },
    address() {
      return this.cart.deliveryAddress
    },
  },
  mounted() {
    if (this.cart.contactThread?.name) {
      this.thread = this.cart.contactThread
    }

    // if (this.$store.state.cart.seller) {
    //   this.getSellerSalesCount(this.getObjectId(this.$store.state.cart.seller))
    // }
  },
  methods: {
    netPriceByAmount,
    netPriceAfterCartDiscount,
    grossPrice,
    isButtonDisabled() {
      if (this.isOfferPath && (!this.cart.offerValidityDays || !(typeof this.cart.offerWebTemplate === 'object' ? this.cart.offerWebTemplate?.id : this.cart.offerWebTemplate))) return true
      // if (!this.isOfferPath && this.cart.deliveryAddressType === 'CONTACT_ADDRESS' && !this.address) {
      //   return true
      // }
      if (!this.isOfferPath && this.cart.deliveryAddressType !== 'CONTACT_ADDRESS' && this.isOtherAddressFilled) {
        return true
      }

      if (!this.isOfferPath && this.cart.deliveryAddressType !== 'CONTACT_ADDRESS' && this.isOtherAddressFilled) {
        return true
      }

      // if (this.checkRequiredModule('marketingModule')) {
      //   if (!this.isOfferPath && (this.$store.state.cart.sellerCommissionParentEnabled || this.$store.state.cart.sellerCommissionEnabled) && this.$refs?.order?.productsNotInPlan().length) {
      //     return true
      //   }
      // }

      // if (!this.isOfferPath && this.cart.sellerDiscountEnabled) {
      //   let flag = false
      //   this.products.forEach(p => {
      //     const productPlanCommissions = p.shopProduct?.shopProductMarketingPlans[0]?.shopProductMarketingPlanCommissions || []
      //     if (productPlanCommissions.length) {
      //       if (p.sellerDiscount > p.commission) {
      //         flag = true
      //       }
      //     }
      //   })
      //
      //   return flag
      // }

      return false
    },
    /* eslint-disable */
    async nextStep() {
      if (this.isButtonDisabled()) {
        this.showToast('danger')
      }

      if (
        !(this.checkRequiredModule('agreementModule') && this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF], [this.$roles.CONTACT_THREAD_AGREEMENT_ADD]))
          && !(this.checkRequiredModule('paymentModule'))
      ) {
        if (this.cart.files && this.cart.files.length) {
          const payload = {
            uploadedFiles: this.cart.files.filter(file => !file?.token),
            type: 'building',
          }
          const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, payload)

          this.$set(this.cart, 'files', [
            ...files,
            ...this.cart.files.filter(file => file?.token)
              .map(file => file.token),
          ])
        }

        // Addresses
        if (this.checkRequiredModule('orderModule') && this.cart.type === 'ORDER' && this.cart.deliveryAddressType === 'OTHER_ADDRESS') {
          const response = await axiosIns.post('1/addresses', this.cart.otherAddress)

          if (response?.data?.data?.items.length) {
            const id = response?.data?.data?.items[0].id
            this.cart.deliveryAddress = id
            const ids = (this.cart.contactThread.contact?.deliveryAddresses?.map(i => this.getObjectId(i))
              .filter(Boolean) || [])
            ids.push(id)

            const contactPayload = {
              id: this.cart.contactThread.contact.id,
              deliveryAddresses: ids,
            }
            await this.$store.dispatch(`contacts/${EDIT_CONTACT}`, contactPayload)
          }
        }

        await this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: this.cart })

        try {
          const finalizeCart = await axiosIns.post('1/contacts/threads/cartDrafts/me/doFinalize')
          let path = ''
          if (this.checkRequiredModule('offerModule') && this.cart.type === 'OFFER') path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/offers`
          else if (this.checkRequiredModule('orderModule') && this.cart.type !== 'OFFER') path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/orders`
          else if (this.checkRequiredModule('paymentModule')) path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/payments`
          else path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/timeline`

          await this.$router.push(path)
          const cart = await this.$store.dispatch(`cart/${GET_USER_CART}`)

          this.showToast('success', this.$i18n.t('success.contactUpdated'))
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
        // eslint-disable-next-line no-unused-vars
        const ref = this.$refs.order
      }

      this.$emit('nextStep')
    },
    changeFiles(files) {
      this.cart.files.push(...files)
    },
    async changeSettings(value, place) {
      this.$set(this.cart, place, value)

      this.$nextTick(() => {
        // Dispatch update cart and skip reload request
        this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: this.cart, updateFiles: false, skip: true })
      })
    },
    async createOffer() {
      this.cart.paymentType = 'CASH'

      // Upload offer files
      if (this.cart.files && this.cart.files.length) {
        const payload = { uploadedFiles: this.cart.files.filter(file => !file?.token), type: 'building' }
        const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, payload)

        this.$set(this.cart, 'files', [
          ...files,
          ...this.cart.files.filter(file => file?.token).map(file => file.token),
        ])

        // if (!this.allowOffer) {
        //   this.$set(this, 'cart', {
        //     name: 'Oferta ukryta',
        //     overwriteVat: null,
        //     sendEmail: false,
        //     offerValidityDays: 100,
        //     offerWebTemplate: '',
        //     files: [],
        //   })
        // }

      }
      await this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: this.cart })

      try {
        const finalizeCart = await axiosIns.post('1/contacts/threads/cartDrafts/me/doFinalize')
        let path = ''
        if (this.checkRequiredModule('offerModule') && this.cart.type === 'OFFER') path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/offers`
        else if (this.checkRequiredModule('orderModule') && this.cart.type !== 'OFFER') path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/orders`
        else if (this.checkRequiredModule('paymentModule')) path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/payments`
        else path = `/contact/${this.cart.contactThread.contact.id}/thread/${this.cart.contactThread.id}/timeline`

        await this.$router.push(path)
        const cart = await this.$store.dispatch(`cart/${GET_USER_CART}`)

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
  },
}
</script>
