<template>
  <div>
    <b-row>
      <b-col
        sm="12"
        md="12"
        lg="12"
      >
        <b-card
          no-body
          class="p-1"
        >
          <validation-observer tag="form">
            <b-row>
              <b-col
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('Name')"
                >
                  <b-form-group :label="$t('Name')">
                    <b-form-input
                      v-model="$store.state.cart.name"
                      :state="errors.length > 0 ? false:null"
                      @blur="changeSettings($event.target.value, 'name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
              >
                <div class="d-flex flex-wrap align-items-center">
                  <b-form-group class="flex-grow-1 mr-25">
                    <label
                      v-b-tooltip
                      for="offer-thread-1"
                      class="mb-50"
                      :title="$t('offer.assignOffer')"
                    >
                      {{ $t('SelectContactAndThread') }}
                      <feather-icon
                        icon="AlertCircleIcon"
                        class="text-primary ml-25"
                      />
                    </label>
                    <sw-select>
                      <v-select
                        id="offer-thread-1"
                        v-model="$store.state.cart.contactThread"
                        :options="threads"
                        label="name"
                        :filterable="false"
                        :selectable="(thread) => thread.offersTotal < system.contactThreadOffersLimit"
                        @search="getThreads"
                        @option:selected="changeSettings($event, 'contactThread')"
                      >
                        <template #no-options="{ search }">
                          <span v-if="search.length">
                            {{ $t('NoData') }}
                          </span>
                          <span v-else>
                            {{ $t('TypeToSearch') }}
                          </span>
                        </template>
                        <template #option="{ name, contact, offersTotal }">
                          <div class="d-flex align-items-center">
                            <avatar
                              :user="contact"
                              class="mr-25"
                            />  - {{ name }}
                            <feather-icon
                              v-if="offersTotal >= system.contactThreadOffersLimit"
                              v-b-tooltip
                              icon="AlertCircleIcon"
                              class="ml-50"
                              :title="$tc('ThreadOfferCountIsToBig', system.contactThreadOffersLimit, { limit: system.contactThreadOffersLimit })"
                            />
                          </div>
                        </template>

                        <template #selected-option="{ name, contact }">
                          <div class="d-flex">
                            <avatar
                              :user="contact"
                              class="mr-25"
                            />  - {{ name }}
                          </div>
                        </template>
                      </v-select>
                    </sw-select>
                  </b-form-group>

                  <b-button
                    size="sm"
                    class="mt-50"
                    variant="primary"
                    @click="showNewContactModal = true"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    {{ $t('AddContact') }}
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="d-flex flex-row-reverse">
      <!--    Views: Order    -->
      <b-col
        sm="12"
        lg="5"
      >
        <!--    Order Header    -->
        <h5 class="mb-1">
          {{ $t('Offer.PriceDetails') }}
        </h5>

        <!--    Order Content    -->
        <b-card style=" position: sticky; top: 7rem;">
          <b-row>
            <!--    Order Details    -->
            <b-col
              sm="12"
              class="d-flex flex-wrap flex-column flex-md-row"
              style="row-gap: .5rem; column-gap: .5rem;"
            >
              <div
                v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_PRODUCT_DISCOUNT])"
                class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100 "
              >
                <p class="mb-0">
                  {{ $t('Offer.TotalNetPrice') }}:
                </p>

                <p class="h6 mb-0 text-success">
                  {{ totalProductsNetPrice | priceFormat }} PLN
                </p>
              </div>

              <div
                v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CART_PRODUCT_DISCOUNT])"
                class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100 "
              >
                <p class="mb-0">
                  {{ $tc('Offer.Discount', 2) }}:
                </p>

                <p class="h6 mb-0 text-success">
                  {{ totalProductsNetPriceAfterDiscount - totalProductsNetPrice | priceFormat }} PLN
                </p>
              </div>

              <div class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100">
                <p class="mb-0">
                  {{ $t('Offer.TotalNetPriceAfterDiscount') }}:
                </p>

                <p class="h6 mb-0">
                  {{ totalProductsNetPriceAfterDiscount | priceFormat }} PLN
                </p>
              </div>

              <div class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100">
                <p class="mb-0">
                  {{ $t('Offer.ValueVAT') }}:
                </p>

                <p class="h6 mb-0 d-inline-flex align-items-center">
                  {{ totalProductsGrossPrice - totalProductsNetPriceAfterDiscount | priceGrossFormat }} PLN
                </p>
              </div>
            </b-col>

            <hr class="separator my-1">

            <!--    Order Total    -->
            <b-col sm="12">
              <div class="d-flex flex-column flex-md-row flex-md-wrap justify-content-between align-content-center w-100">
                <h6>{{ $t('Offer.TotalGrossPrice') }}:</h6>

                <h5>
                  {{ totalProductsGrossPrice | priceGrossFormat }} PLN
                </h5>
              </div>

              <b-button
                :disabled="!products.length || !cart.contactThread || !cart.name"
                variant="primary"
                class="w-100 mt-1"
                @click="$emit('nextStep')"
              >
                {{ $t('Next') }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--    Views: Products    -->
      <b-col
        sm="12"
        md="12"
        lg="7"
      >
        <!--    Products Header    -->
        <div class="d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <div class="font-medium-1 font-weight-bold mr-50">
              {{ $t('Offer.Products') }}
            </div>

            <!-- Price View -->
            <b-button-group
              v-if="false"
              size="sm"
            >
              <b-button
                v-b-tooltip
                :variant="priceViewType === 'PRICE' ? 'primary' : 'outline-primary'"
                title="Cena w walucie"
                @click="priceViewType = 'PRICE'"
              >
                <feather-icon icon="DollarSignIcon" />
              </b-button>
              <b-button
                v-b-tooltip
                :variant="priceViewType === 'POINTS' ? 'primary' : 'outline-primary'"
                title="Cena w punktach lojalnościowych"
                :disabled="!isSomeProductHaveLoyaltyPlan"
                @click="priceViewType = 'POINTS'"
              >
                <feather-icon icon="StarIcon" />
              </b-button>
            </b-button-group>
          </div>
        </div>

        <!--    Products Content    -->
        <div class="shopCart mt-1 d-flex flex-column">
          <!--    Cart Items    -->
          <div v-if="products.length > 0">
            <div
              v-for="(item, index) in products"
              :key="item.id"
              class="position-relative shopCartItem mb-1 pb-md-0"
            >
              <product
                :product="item"
                :is-on-overwrite-vat-in-cart="isOnOverwriteVatInCart"
                :overwrite-vat-in-cart="overwriteVatInCart"
                :price-view-type="priceViewType"
                @remove="removeProduct(item.id, index)"
                @update-vat="updateVat($event, index, item.id)"
                @update-discount="updateDiscount($event, index, item.id)"
              />
            </div>
          </div>

          <div
            v-else
            class="position-relative text-center mb-1 pb-1 pb-md-0"
          >
            <h3 class="emptyCart">
              {{ $t('YourCartIsEmpty') }}
            </h3>
          </div>
        </div>

        <b-card
          v-if="isOverwriteVatInCart"
          class="mt-2"
        >
          <b-form-checkbox
            v-model="isOnOverwriteVatInCart"
            class="custom-control-primary mb-1"
            variant="primary"
            switch
          >
            {{ $t('TurnOnGlobalCartVat') }}
          </b-form-checkbox>

          <sw-select :name="$t('setVatValueForAllProductsOfOrder')">
            <v-select
              v-model="overwriteVatInCart"
              :disabled="!isOnOverwriteVatInCart"
              :options="['0', '8', '23']"
              :clearable="false"
            />
          </sw-select>
        </b-card>
      </b-col>
    </b-row>

    <!--   Add new Contact modal   -->
    <b-modal
      :visible="showNewContactModal"
      size="xl"
      hide-footer
      @hidden="showNewContactModal = false"
    >
      <create-contact
        is-modal-view
        @close="(e) => {showNewContactModal = false; setNewContact(e)}"
      />
    </b-modal>

    <!--   Add new Thread modal   -->
    <b-modal
      :visible="showNewThreadModal"
      size="xl"
      hide-footer
      @hidden="showNewThreadModal = false"
    >
      <thread-modal
        v-if="newContact"
        :ok-btn="$t('Save')"
        :contact-id="newContact.id"
        @close-modal="setNewThread"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  GET_USER_CART, REMOVE_PRODUCT_FROM_CART, UPDATE_CART, UPDATE_CART_PRODUCT,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import vSelect from 'vue-select'
import {
  grossPrice,
  loyaltyPointsPrice,
  netPriceAfterCartDiscount,
  netPriceByAmount,
} from '@/helpers/cartProductPrices'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { THREADS_SEARCH } from '@/@constants/fields'
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import CreateContact from '@/pages/contact.old/Create.vue'
import ThreadModal from '@/views/modals/contact/ThreadModal.vue'
import product from './CheckoutProduct.vue'

export default {
  components: {
    ThreadModal,
    CreateContact,
    product,
    BButtonGroup,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isOverwriteVatInCart: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['nextStep'],
  data: () => ({
    showNewContactModal: false,
    showNewThreadModal: false,
    newContact: null,
    ownVat: false,
    priceViewType: 'PRICE', // PRICE || POINTS
    ///    Products Items    ///
    discountType: false,
    value: 0,
    threads: [],
    ///    Price Details    ///
    inDiscount: null,
    discount: 0,
    totalNet: 0,
    totalVAT: 0,
    total: 0,
    isOnOverwriteVatInCart: false,
    overwriteVatInCart: null,
    cleaveOptions: {
      number: {
        delimiter: ' ',
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    },
  }),
  watch: {
    'cart.overwriteVat': {
      deep: true,
      handler(newValue) {
        if (newValue !== null) {
          this.overwriteVatInCart = String(newValue)
          this.isOnOverwriteVatInCart = true
        } else if (newValue === null) {
          this.overwriteVatInCart = null
          this.isOnOverwriteVatInCart = false
        }
      },
    },
    isOnOverwriteVatInCart(newValue) {
      if (newValue) this.overwriteVatInCart = this.overwriteVatInCart ?? 0
      else this.overwriteVatInCart = null
    },
    overwriteVatInCart(newValue) {
      this.products.forEach((_, index) => {
        this.$set(this.products[index], 'overwriteVat', newValue)
      })

      this.cart.overwriteVat = newValue === null ? null : Number(newValue)
      this.updateProductsWithLoadSkip()
    },
  },
  async mounted() {
    if (this.checkRequiredModule('offerModule')) {
      await this.$store.dispatch(`cart/${GET_USER_CART}`)
    }
  },
  computed: {
    ...mapGetters({
      cart: 'cart/getCart',
      products: 'cart/getCartProducts',
      thread: 'cart/getCartThread',
      system: 'system/getSettings',
    }),
    totalProductsGrossPrice() {
      this.$emit('set-total-price', (this.products.length ? this.products.reduce((a, b) => +a + (this.grossPrice(b)), 0) : 0))
      return this.products.length ? this.products.reduce((a, b) => +a + (this.grossPrice(b)), 0) : 0
    },
    totalProductsNetPrice() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.netPriceByAmount(b)), 0) : 0
    },
    totalProductsNetPriceAfterDiscount() {
      return this.products.length ? this.products.reduce((a, b) => +a + (this.netPriceAfterCartDiscount(b)), 0) : 0
    },
    isSomeProductHaveLoyaltyPlan() {
      return this.products.some(({ shopProduct }) => !!shopProduct?.shopProductLoyaltyPlans?.length)
    },
  },
  methods: {
    netPriceByAmount,
    netPriceAfterCartDiscount,
    grossPrice,
    loyaltyPointsPrice,
    setNewThread(thread) {
      this.changeSettings({ ...thread, contact: this.newContact }, 'contactThread')
      this.showNewThreadModal = false
      this.newContact = null
    },
    setNewContact(contact) {
      if (contact?.contactThread) {
        // eslint-disable-next-line no-underscore-dangle
        const _contact = contact
        const thread = contact.contactThread
        delete _contact.contactThread

        this.changeSettings({ ...thread, contact: _contact }, 'contactThread')
      } else {
        this.showAlert('info', this.$i18n.t('RequiredAdditionalAction'), this.$i18n.t('ContactAddedSuccessfullyHoweverThreadIsRequired'))
          .then(() => {
            this.showNewThreadModal = true
            this.newContact = contact
          })
      }
    },
    // Change cart settings
    async changeSettings(value, place) {
      this.$set(this.cart, place, value)

      this.$nextTick(() => {
        // Dispatch update cart and skip reload request
        this.$store.dispatch(`cart/${UPDATE_CART}`, { cart: this.cart, updateFiles: false, skip: place !== 'contactThread' })
      })
    },
    async getThreads(search, loading) {
      this.threads = await this.selectSearch(search, loading, THREADS_SEARCH, 'name,contact-firstName,contact-lastName', '1/contacts/threads', 30)
    },
    removeProduct(productId, index) {
      this.$store.dispatch(`cart/${REMOVE_PRODUCT_FROM_CART}`, { productId })
        .then(() => {
          this.products.slice(index, 1)
          this.showToast('success', this.$i18n.t('ProductRemovedFromCard'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err.response.data.message}`))
        })
    },
    updateDiscount(discount, index, id) {
      const payload = {
        id,
        ...discount,
        discountType: discount.discountType || 'NETTO_VALUE',
      }
      this.$store.dispatch(`cart/${UPDATE_CART_PRODUCT}`, { product: payload })
    },
    // eslint-disable-next-line no-unused-vars
    updateVat(item, index, id) {
      const payload = {
        id,
        overwriteVat: Number(item),
      }

      this.$set(this.products[index], 'overwriteVat', Number(item))
      this.$store.dispatch(`cart/${UPDATE_CART_PRODUCT}`, { product: payload, skip: true })
    },
    async updateProductsWithLoadSkip() {
      const payload = this.products.map(p => ({ id: p.id, overwriteVat: p.overwriteVat == null ? null : Number(p.overwriteVat) }))

      await this.$store.dispatch(`cart/${UPDATE_CART_PRODUCT}`, { product: payload, skip: true })
      await this.$store.dispatch(`cart/${UPDATE_CART}`, {
        cart: this.cart,
        updateFiles: false,
        skip: true,
        overwriteVat: this.cart.overwriteVat === null ? null : String(this.cart.overwriteVat),
      })
    },
  },
}
</script>
